$modal-popup-breakpoint-screen__m: $screen__m + 1 !default;
$modals-wrapper__z-index         : $z-index-highest + 1 !default;

.modal-popup {
    left: 0;

    .field-tooltip {
        .field-tooltip-content {
            @extend .abs-checkout-tooltip-content-position-top;
        }
    }

    .modal-inner-wrap {
        background-color: $gray-lightest;

        @include mq($screen__l) {
            margin-left: -400px;
            width: 800px;
            left: 50%;
        }

        .field.street {
            border: none;
            padding: 0;
        }
    }

    .modal-header {
        .modal-title {
            padding-bottom: 10px;
            border-bottom: 1px solid #d9d9d9;
        }
    }

    .modal-footer {
        border-top: 1px solid #d9d9d9;

        .action-save-address {
            margin-bottom: 15px;

            @include mq($screen__m) {
                width: auto;
                float: right;
                margin: 0 0 0 $indent__s;
            }
        }

        .action-hide-popup {
            @include mq($screen__m) {
                width: auto;
            }
        }
    }
}

.modals-wrapper {
    z-index: $modals-wrapper__z-index;
}
