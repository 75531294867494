@import 'store-pickup-variables';

.store-pickup {
    ~ .checkout-shipping-address,
    ~ .checkout-shipping-method {
        @include visually-hidden;
    }

    &__tabs {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: $store-pickup__tabs-margin-bottom;
    }

    &__tab {
        @extend .tab__title;
        max-width: $store-pickup__tab-max-width;

        &--active {
            @extend .tab__title--active;
        }
    }

    &__heading {
        padding: $store-pickup__heading-padding;
    }

    &__section {
        margin-bottom: $store-pickup__section-margin-bottom;
    }

    &__paragraph {
        margin-bottom: $store-pickup__paragraph-margin-bottom;
    }

    &__actions {
        width: $store-pickup__actions-width;
        display: flex;
        justify-content: $store-pickup__actions-justify-content;
    }

    &__action-select {
        margin-right: $store-pickup__action-select-margin-right;
    }

    &__modal-content {
        display: none;

        .modal-content & {
            display: block;
        }
    }
}
