*:focus {
  outline: none !important;
}
p {
  margin-bottom: 1em;
}

body {
  &:not(.catalog-product-view):not(.cms-index-index) {
    background: url(../images/backgrounds/page-bg.jpg) top 49px center / auto
      170px no-repeat;

    @include mq($screen-m) {
      background-position: top center;
      background-size: contain;
    }
  }

  .slider {
    margin: 0 auto 30px;
  }

  &._has-modal-custom {
    #maincontent {
      z-index: 12;
    }
  }
}

.link,
a {
  outline: none !important;
}

.homepage-lower-content {
  .home-gift-cards,
  .youth-discount {
    padding: 20px 0;
    a {
      display: block;
      &:hover {
        text-decoration: none;
      }
    }
    h1 {
      color: #fff;
      font-size: 4em;
      letter-spacing: 0;
      text-transform: none;
      margin-bottom: 0;
    }
    h2 {
      font-size: 3em;
      text-transform: none;
      color: #fff;
      letter-spacing: 0;
    }
    p {
      color: #fff;
    }
    button {
      color: #ffffff;
      line-height: 45px;
      padding: 15px 0 0 0;
      border: 0;
      font-size: 40px;
      border-bottom: 2px solid #fff;
      background: none !important;
      white-space: nowrap;
      transition: $transition-base;
      margin-bottom: 30px;
      &:hover {
        border-bottom: 2px solid transparent;
      }
    }
  }
  .youth-discount {
    background: url(../images/backgrounds/youth-discount.jpg) center / cover
      no-repeat;
  }
  .home-gift-cards {
    background: #111;
    padding: 20px 0;
    margin-bottom: 30px;
  }
  @include mq($screen-m) {
    .home-gift-cards,
    .youth-discount {
      padding: 50px;
      margin-bottom: 0;
    }
  }
  @include mq($screen-l) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

#maincontent {
  position: relative;
  z-index: 2;
  background: #ffffff;
  margin-bottom: 80px;

  ul {
    line-height: 1.5;
    color: $paragraph-color;
  }
}

.heading,
h1,
h2,
h3,
h4,
h5,
h6 {
  + .divider {
    display: none;
  }
}

.minicart-content__bar {
  display: none;
}

.registration__divider {
  display: none;
}

.table th {
  color: #ffffff;
  font-family: $headings-font-family;
}

.category-description {
  margin-bottom: $spacer--extra-large;
  padding: 0 $spacer--extra-large;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#maincontent.homepage {
  margin: 0px 0px 0px 0px;
  padding: 8px;
  max-width: 100%;
}

.cms-about-us {
  background: url(../images/backgrounds/fabric.jpg) top 98px center / contain
    no-repeat !important;

  .breadcrumbs {
    display: none;
  }

  #maincontent {
    margin-bottom: 0;

    &.container {
      max-width: unset;
    }
  }

  .title-wrapper {
    max-width: 1328px;
    margin: 0 auto;
  }

  h2 {
    letter-spacing: 0.4em;
    display: inline-block;
    background: $black;
    color: $white;
    padding: 10px 20px;
  }
}

.message:not(.global) {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  z-index: 3;
}

.button {
  text-decoration: none;
  font-family: "Favorit";
  font-weight: 400;
  line-height: 1.1;
  text-transform: uppercase;
}
.button--secondary {
  color: #fff;
  &:hover {
    background-color: #333;
    &::before {
      z-index: 2;
    }

    &::after {
      z-index: 3 !important;
      display: block !important;
    }
  }
}

.product.attribute.lead {
  width: 100%;
  color: $product-view__sku-color;
  font-size: 12px;
  text-align: left;

  .type {
    display: none;
  }

  .value {
    color: $red;
    font-size: 16px;
    margin-bottom: $spacer--medium;
    font-weight: bold;
  }
}
.product-view__buttons {
  .product-view__add-to-cart {
    .button__text {
      display: inline-block !important;
    }
  }
}
.curator {
  .crt-post {
    .crt-post-footer {
      .crt-post-userimage {
        width: 24px !important;
        max-width: 24px !important;
        height: 24px !important;
        max-height: 24px !important;
      }
    }
  }
}

.configurable-variation-qty {
  display: none;
}

.mega-menu__inner-link {
  min-height: auto !important;
}

.ratio-container picture.ratio-image {
  position: absolute;
  top: 0;
  left: 0;
  transform: initial;
  max-width: 100%;
  bottom: 0;
  right: 0;
}

.modals-wrapper {
  z-index: 100;
}
.cat-list {
  .cat {
    border: 5px solid #000;
    min-height: 220px;
    @include mq($screen-m) {
      border: 10px solid #000;
    }
    .category-item-name {
      left: 0;
      bottom: 0;
      right: 0;
      background: #000;
      text-shadow: none;
    }
  }
}

.product-view {
  .product-view__details {
    .product-view__buttons {
      .smart-buttons {
        display: none !important;
      }
    }
  }
}

.checkout-index-index {
  .opc-wrapper {
    #payment {
      .payment-methods {
        .payment-method {
          .payment-method-title {
            display: flex;
            .radio:checked + .label:after, .radio:checked + label:after {
              top: 3px !important;
            }
          }
          .payment-icon, .payment-type-icon {
            height: 28px !important;
            right: 0px !important;
            width: auto !important;
            position: absolute;
            top: 0px;
          }
        }
      }
    }
  }
}