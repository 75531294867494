.loading-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: rgba(255, 255, 255, 0.5);

    .loader {
        & > p {
            display: none;
        }
    }
}

body {
    & > .loader-mask {
        z-index: $z-index-high;
    }
}

._block-content-loading {
    position: relative;
}
