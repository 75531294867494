// Overwrite Magento_UI vars
$border-color__base                           : $gray-light !default;
$layout-column__sidebar-width                 : 5 !default;
$dropdown-list__z-index                       : $z-index-high !default;
$form-field-type-label-inline__width          : 25.8% !default;

$checkout-tooltip-content__background-color   : $gray-lighter !default;
$checkout-tooltip-content__border-color       : $gray-lighter !default;

$checkout-payment-option-title-icon__font-size: $font-size-base !default;
$checkout-payment-option-title-icon__margin   : 0 0 0 $spacer--small !default;

$checkout-step-title__border                  : $border-base !default;
$checkout-shipping-item__active__border-color : $color-primary !default;

// Checkout components global vars
$checkout__messages-width                     : 100% !default;
$checkout-container__width                    : 1120px !default;
$checkout-container__margin                   : 0 auto !default;
$checkout__max-width\@medium                  : 70% !default;
$progress-bar__width                          : 80% !default;
$checkout-messages__width                     : 100% !default;
$checkout-messages__margin                    : 0 auto !default;
$opc-wrapper__margin                          : 0 auto !default;
$opc-wrapper__width                           : 60% !default;
$opc-wrapper__padding\@large                  : 0 80px 0 0 !default;
$opc-wrapper__padding\@xlarge                 : 0 120px 0 0 !default;
$opc-sidebar__width\@medium                   : 40% !default;
