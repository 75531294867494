.page-layout-checkout {
    .opc-progress-bar-item {
        &._active {
            &:before {
                background: $color-primary !important;
            }

            > span {
                &:before {
                    background: $color-primary !important;
                }
            }
        }
    }

    .checkout-summary {
        background: $c-grey-light;

        &__title {
            color: #ffffff;
            background: $color-primary;
            display: inline-block;
            padding: 10px 20px;
        }
    }

    .opc-wrapper .shipping-address-item.selected-item {
        border-color: $color-primary !important;
    }

    .page-main .action.primary {
        background: $button__background !important;
        padding: $button__padding !important;
        border: $button__border !important;
        border-radius: $button__border-radius !important;
        font-size: $button__font-size !important;
        color: $button__font-color !important;
        transition: $button__transition !important;
        font-weight: $button__font-weight !important;

        &:hover {
            background: $button__background-hover !important;
            color: $button__font-color-hover !important;
        }
    }

    .action-close {
        background: $c-black !important;
    }
}

.shipping-estimation {
    border-top: 1px solid #d9d9d9;

    &.active {
        .shipping-estimation__title-icon {
            transform: rotate(180deg);
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        cursor: pointer;

        &-text {
            font-weight: 500;
        }

        &-icon {
            width: 14px;
            height: 14px;
            transition: $transition-base;
        }
    }

    &__content {
        display: none;
        padding-bottom: 20px;
    }

    &__form {
        padding-top: 20px;
    }
}

.cart__products-list {
    border-top: 0;
}

.cart-summary__checkout-button,
.payment-method-content .action.primary.checkout {
    &:not(:hover) {
        background: $green !important;
    }
}

.success-content-wrapper-inner {
    background: url(../images/bag.png) top center no-repeat;
    background-size: 242px 193px;
    margin-bottom: 100px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    padding-top: 250px;
    text-align: center;

    @include mq($screen-s) {
        background-size: 338px 270px;
        padding-top: 300px;
    }

    @include mq($screen-l) {
        padding-left: 400px;
        padding-top: 0;
        min-height: 500px;
        background-position: left center;
        text-align: left;
        margin-top: 0;
    }

    @include mq($screen-xl) {
        padding-left: 500px;
        background-size: 437px 349px;
    }

    > *:first-child {
        margin-top: auto !important;
    }

    > *:last-child {
        margin-bottom: auto !important;
    }

    .title-wrapper {
        margin: 0 0 $spacer--medium;

        h1 {
            background: $color-primary;
            color: #ffffff;
            text-align: center;
            padding: 5px 20px;
            margin: 0 0 2px;
            display: inline-block;

            @include mq($screen-l) {
                padding: 10px 200px;
            }
        }
    }

    .checkout-success {
        p {
            color: $black;

            span {
                font-weight: bold;
                font-size: 18px;
            }
        }

        .action {
            span {
                margin: auto;

                @include mq($screen-l) {
                    margin: 0;
                }
            }
        }
    }

    #registration {
        margin-top: $spacer--large;
        background: $c-grey-light;
        padding: 50px;

        br {
            display: none;
        }

        .action {
            background: $black !important;
            padding: $button__padding !important;
            border: none !important;
            border-radius: $button__border-radius !important;
            font-size: $button__font-size !important;
            color: $white !important;
            transition: $button__transition !important;
            font-weight: $button__font-weight !important;
            text-transform: uppercase;
            display: inline-block;
            width: auto;
            display: inline-flex;
            text-decoration: none;
        }
    }
}

.form-login {
    #customer-email {
        height: $search-form__input-height;
        border: solid 1px $c-grey;
        font-size: $search-form__font-size;
        background: #fff;
        padding: $input__field-padding;

        &::placeholder {
            color: $search-form__placeholder-color;
            font-size: $search-form__font-size;
        }
    }
}

.page-layout-checkout .checkout-payment-method .payment-method-title > .label {
    display: inline !important;
}

label[for="magenest_sagepay_form"] span::before {
    content: url(/media/sage-pay.png);
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
}

.payment-icon {
    object-fit: scale-down;
}

.payment-method-title #paypal-express {
    bottom: 16px;
}

#shipping_cp_button_id {
    padding: 0 18px !important;
}
.payment-methods .payment-method-title .radio:checked + .label:after {
    top: -8px !important;
    left: 3px !important;
    content: "" !important;
    width: 18px !important;
    height: 18px !important;
}

.payment-methods .payment-icon {
    height: 28px !important;

    right: -60px !important;
}

.progress-bar {
    h1,
    h2,
    h3,
    h4 {
        font-family: $font-family-base !important;
    }
}
