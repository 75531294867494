.filters {
    &__heading {
        color: #ffffff;
        background: $color-primary;
        display: inline-block;
        padding: 10px 20px;
    }

    &__divider {
        display: none;
    }

    &__trigger {
        .button__text {
            text-transform: capitalize;
            font-weight: 400;
        }
    }

    .modal {
        top: 97px;
    }
}

.filters__heading {
    font-weight: 400;
}
.filters__content {
    width: 100%;
}
.filters__content-middle {
    padding: 8px !important;
}

.dropdown-list {
    &--secondary {
        .dropdown-list__label {
            display: flex;
            justify-content: space-between;
        }
    }

    &__item {
        &--collapse {
            .dropdown-list__label {
                padding-right: 0;
            }
        }

        + .dropdown-list__item {
            border-top: 1px solid $c-grey-light;
            padding-top: 5px;
            margin-top: 20px;
        }
    }

    .checkbox {
        font-family: $font-family-base;

        &__label {
            padding: 3px 0;

            &:before {
                display: none;
            }
        }

        &__icon {
            display: none;
        }
    }
    .checkbox__label {
        font-size: 14px;
    }
}
