#maincontent {
    > .title-wrapper,
    .banner {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        margin-bottom: 0;

        .banner__button-collapse {
            &:hover {
                background: transparent !important;
            }
        }

        .heading--page {
            font-family: $headings-font-family;
            text-transform: uppercase;
            margin: 30px auto;
            text-align: center;
            font-size: 16px;

            @include mq($screen-m) {
                margin: 60px auto;
            }

            span {
                color: #ffffff;
                background: $color-primary;
                display: inline;
                padding: 5px 10px;
                box-decoration-break: clone;
                line-height: 230%;
                font-size: 12px;
                font-weight: normal;
                letter-spacing: 0.4em;

                @include mq($screen-m) {
                    font-size: 16px;
                    padding: 10px 20px;
                    line-height: 270%;
                }

                @include mq($screen-l) {
                    font-size: 22px;
                    line-height: 230%;
                }
            }
        }
    }
}

.checkout-index-index,
.checkout-cart-index,
[class*="checkout-onepage"],
.account,
.account {
    #maincontent {
        > .title-wrapper {
            margin-bottom: 30px;
        }
    }
}
.page-products {
    #maincontent {
        > .banner .banner__heading {
            position: relative;
            overflow: hidden;
            .heading--page {
                position: relative;
                z-index: 4;
            }
            .category-title-bg {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                z-index: 2;
            }
        }
    }
}
