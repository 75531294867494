.header-usp-wrapper {
    display: flex;
    margin-bottom: 30px;
    background: #fff;

    @include mq($screen-m) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2px;
        grid-gap: 15px;
    }

    .usp {
        text-align: center;
        padding: 15px 10px;

        display: flex;
        flex-direction: column;

        @include mq($screen-m) {
            grid-auto-columns: 1fr;
            grid-template-columns: 1fr;
            display: grid;
            grid-column-gap: 1rem;
            text-align: center;
        }

        @include mq($screen-l) {
            padding: 15px 30px;
        }

        h1,
        h2,
        h3,
        h4 {
            font-size: $heading-font-size--fifth-level;
            margin: auto 0;
            font-weight: 400;
            line-height: 1.5;
            font-size: 0.5em;

            @include mq($screen-m) {
                margin-bottom: 3px;
                font-size: 0.9em;
            }
        }

        p {
            display: none;
        }

        &_icon {
            width: 59px;
            height: 20px;
            margin: 0 auto 10px;

            @include mq($screen-m) {
                width: 88px;
                height: 30px;
                margin: 0 auto 10px;
            }

            svg {
                display: block;
                object-fit: contain;
                width: 100%;
                height: 100%;
                margin: auto;
            }
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }
}

.pdp-usp-wrapper {
    .usp {
        display: flex;
        align-items: center;
        margin: 1em 0px;

        h1,
        h2,
        h3,
        h4 {
            font-size: 12px;
            margin: 0;
        }

        p {
            display: none;
        }

        &_icon {
            height: 20px;
            margin: 0 10px 0 0;
            width: 20%;

            @include mq($screen-m) {
                height: 30px;
            }

            svg {
                display: block;
                object-fit: contain;
                height: 100%;
                margin: auto;
                max-width: 80px;
            }
        }
        h1 {
            width: 80%;
        }
    }
}
