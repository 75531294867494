.search-form {
    border-radius: 0;

    &__input {
        border-radius: 0;
        background: $c-grey-light;

        &::placeholder {
            font-style: italic;
            opacity: 0.5;
        }
    }
}