.product.data.items {
    @include lib-data-accordion();
    margin-bottom: $indent__base;
}

@include mq($screen__m) {
    .product.data.items {
        @include lib-data-tabs();
    }
}

@include mq($max-screen: $screen__m) {
    .product.data.items {
        @include lib-data-accordion();
        .data.item {
            display: block;
        }
    }
}
