.toolbar {
    &__sorter {
        max-width: 70%;
        margin-right: auto;
        border: 0;
    }

    &__limiter {
        width: 30%;
        top: 8px;
        
        @include mq($screen-m) {
            top: auto;
            width: auto;
        }
    }

    .select__field-list--single {
        border: 1px solid rgba(0,0,0,0.2);
    }
}