.credit-card {
    &__date {
        display: flex;
        justify-content: space-between;
    }

    &__select {
        flex-grow: 1;
    }

    &__field {
        width: 100%;

        &.cvv {
            width: auto;
        }
    }
}
