.best-sellers {
    grid-template-columns: 1fr 1fr;
    padding: 0;
    margin: 0 0 30px;
    border: 0;

    @include mq($screen-m) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include mq($screen-l) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &_icon {
        width: 45px;
        margin: 0 auto 15px;
        opacity: 0.3;
    }

    &_title-block {
        background: $color-primary;
        border-color: $color-primary;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 20px;
        grid-column: span 2;

        @include mq($screen-m) {
            grid-column: span 1;
        }

        h1, h2, h3, h4, h5, h6 {
            color: #ffffff;
            font-size: 16px;
        }

        p {
            color: #ffffff;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }  
    
    li:nth-of-type(n+10) {
        display: none !important;
    }

    @include mq($screen-l) {
        li:nth-of-type(n+9) {
            display: none !important;
        }
    }
}