$step-title__font-size     : $font-size-large !default;
$step-title__font-weight   : $font-weight-normal !default;
$step-title__text-transform: uppercase !default;
$step-title__border        : $border-base !default;
$step-title__border-width  : 0 0 1px 0 !default;
$step-title__padding       : 0 0 $spacer 0 !default;
$step-title__margin        : 0 0 $spacer--large 0 !default;

.step-title {
    font-size: $step-title__font-size;
    font-weight: $step-title__font-weight;
    text-transform: $step-title__text-transform;
    border: $step-title__border;
    border-width: $step-title__border-width;
    padding: $step-title__padding;
    margin: $step-title__margin;
}
