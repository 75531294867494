.intro {
    padding: 60px 0 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 50px;

    @include mq($screen-m) {
        column-count: 2;
        column-gap: 50px;
    }
}

.text-bar {
    background: $c-grey-light;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;

    strong {
        font-weight: normal;
        background: $black;
        color: $white;
        padding: 5px 10px;
        font-family: $headings-font-family;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        font-size: $font-size-base;
    }

    :last-child {
        margin-bottom: 0;
    }

    p {
        line-height: 1.7;
    }

    @include mq($screen-s) {
        padding-top: 100px;
        padding-bottom: 100px;

        p {
            font-size: $font-size-large;
        }
    }

    @include mq($screen-m) {
        p {
            font-size: $font-size-extra-large;
        }
    }
}

.image, img, picture {
    width: auto !important;
}

.thumbnail-block {

    @include mq($screen-m) {
        display: flex;        
        align-items: stretch;
    }

    > div {
        width: 100%;        

        &.image {
            .inner {
                height: 100%;
                display: flex;
                align-self: stretch;
                flex-wrap: wrap;
                text-align: center;

                .embed-container {                    
                    align-self: center;
                }
            }

            img {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }

        &.text {
            display: flex;
            align-self: stretch;
            flex-wrap: wrap;
            text-align: center;

            .inner {
                align-self: center;
                padding: 50px 30px;

                @include mq($screen-s) {
                    padding-left: 50px;
                    padding-right: 50px;
                }

                :last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &:nth-of-type(odd) {
        > div { 
            &.image {
                border-right: none;
                order: 1;
            }
        }
    }
}
.wrapper-big-match {
    .home-intro-large {
        padding: 30px;
        background: $c-black;
        h1 {
            color: #fff;
            font-size: 2.4em;
            font-weight: 400;
        }
    }
    @include mq($screen-m) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 2px;
        grid-row-gap: 0px;
        margin-bottom: 30px;
        .home-intro-large {
            h1 {
                font-size: 3.4em;
            }
        }
    }
    @include mq($screen-l) {
        .home-intro-large {
            h1 {
                font-size: 4.4em;
            }
        }
    }
}
.wrapper-big-match + .wrapper {
    padding: 30px;
    border: 1px solid $c-black;
    margin-bottom: 30px;
}