.opc-block-summary {
    .mark {
        .value {
            color: $color-gray60;
        }
    }

    .grand.incl {
        & + .grand.excl {
            .mark,
            .amount {
                border-top: 0;
                font-size: 14px;
                padding-top: 0;

                strong {
                    font-weight: $font-weight__regular;
                }
            }
        }
    }

    .column.main & {
        .product-item {
            margin: 0;
            padding-left: 0;
        }
    }

    .product-item {
        .product-item-inner {
            display: table;
            margin: 0 0 $indent__s;
            width: 100%;
        }

        .details-qty {
            margin-top: 5px;
        }

        .product-item-name-block {
            display: table-cell;
            padding-right: $indent__xs;
            text-align: left;
        }

        .subtotal {
            display: table-cell;
            text-align: right;
        }

        .price-including-tax {
            & + .price-excluding-tax {
                margin: 0;

                .price {
                    font-size: 10px;
                }
            }
        }
    }
}

.opc-summary-wrapper {
    .modal-header {
        .action-close {
            @include mq($screen__m) {
                display: none;
            }
        }
    }
}
