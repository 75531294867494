$shipping-information__margin                 : $spacer--large 0 !default;
$shipping-information__padding                : 0 $spacer--medium $spacer--medium !default;
$shipping-information__title-margin           : 0 0 $spacer--large 0 !default;
$shipping-information__title-border           : $border-base !default;
$shipping-information__title-border-width     : 0 0 $border-width-base 0 !default;
$shipping-information__title-text-align-self  : center !default;
$shipping-information__title-text-transform   : uppercase !default;
$shipping-information__title-text-font-size   : $font-size-large !default;
$shipping-information__title-text-font-weight : $font-weight-normal !default;
$shipping-information__content-line-height    : $font-line-height !default;
$shipping-information__content-margin         : 0 0 $spacer--large 0 !default;
$shipping-information__content-font-size      : $font-size-base !default;
$shipping-information__button-icon-fill--hover: $white !default;
$shipping-information__button-icon-fill       : $gray-dark !default;

.shipping-information {
    margin: $shipping-information__margin;
    padding: $shipping-information__padding;

    &__title {
        display: flex;
        justify-content: space-between;
        margin: $shipping-information__title-margin;
        border: $shipping-information__title-border;
        border-width: $shipping-information__title-border-width;
    }

    &__title-text {
        align-self: $shipping-information__title-text-align-self;
        text-transform: $shipping-information__title-text-transform;
        font-size: $shipping-information__title-text-font-size;
        font-weight: $shipping-information__title-text-font-weight;
    }

    &__content {
        line-height: $shipping-information__content-line-height;
        margin: $shipping-information__content-margin;
        font-size: $shipping-information__content-font-size;
    }

    &__button {
        &:focus,
        &:hover {
            .icon {
                fill: $shipping-information__button-icon-fill--hover;
            }
        }

        .icon {
            fill: $shipping-information__button-icon-fill;
        }
    }
}
