$block-auth__dropdown__padding: $indent__m !default;
$block-auth__or-label__size   : 36px !default;
$block-auth__border           : 1px solid $gray-lighter !default;

.authentication-dropdown {
    box-sizing: border-box;

    .modal-inner-wrap {
        padding: $block-auth__dropdown__padding;
    }
}

.authentication-wrapper {
    max-width: 50%;
    position: relative;
    z-index: 1;

    ._has-auth-shown & {
        z-index: $modal__z-index;
    }

    .popup-trigger {
        width: 150px;
    }
}

.block-authentication {
    .block-title {
        margin-bottom: $indent__m;
    }

    .actions-toolbar {
        margin-bottom: $indent__xs;

        & > .secondary {
            padding-top: $indent__m;
            text-align: left;
        }
    }

    .block[class] {
        margin: 0;

        ul {
            list-style: none;
            padding-left: $indent__s;
        }

        & + .block {
            border-top: 1px solid $color-gray-light5;
            margin-top: $indent__xl;
            padding-top: $indent__xl;
            position: relative;

            &:before {
                height: $block-auth__or-label__size;
                line-height: $block-auth__or-label__size - 2px;
                margin: (-$block-auth__or-label__size / 2 + 1px) 0 0 (-$block-auth__or-label__size / 2);
                min-width: $block-auth__or-label__size;
                background: $color-white;
                border: 1px solid $color-gray-light5;
                border-radius: 50%;
                box-sizing: border-box;
                color: $color-gray-light5;
                content: attr(data-label);
                display: inline-block;
                left: 50%;
                letter-spacing: normal;
                padding: 0 0.2rem;
                position: absolute;
                text-align: center;
                text-transform: uppercase;
                top: 0;
            }
        }
    }
}

@include mq($screen__m) {
    .authentication-dropdown {
        position: absolute;
        top: 100%;
        width: 100%;
        margin-top: 10px;
        background-color: $gray-lightest;
        border: $block-auth__border;
        text-align: left;
        transform: scale(1, 0);
        transform-origin: 0 0;
        transition: transform linear 0.15s, visibility 0 linear 0.15s;
        visibility: hidden;

        &._show {
            z-index: $dropdown-list__z-index;
            transform: scale(1, 1);
            transition: transform linear 0.15s, visibility 0 linear 0;
            visibility: visible;
        }
    }

    .popup-authentication {
        .modal-inner-wrap {
            min-width: $screen__m;
            width: 60%;
        }

        .block-authentication {
            display: flex;
            flex-direction: row;
            border-top: 1px solid $color-gray-light5;
        }

        .block[class],
        .form-login,
        .fieldset,
        .block-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        .block[class] {
            box-sizing: border-box;
            float: left;
            padding: $indent__s $indent__l 0 0;
            width: 50%;

            & + .block {
                border-left: 1px solid $color-gray-light5;
                border-top: 0;
                margin: 0;
                padding: $indent__s 0 0 $indent__xl;

                &::before {
                    left: 0;
                    top: 50%;
                }
            }
        }

        .actions-toolbar {
            margin-bottom: 0;
            margin-top: auto;
        }
    }
}
