// TODO: Refactor and move to Alpaca Components

$modal-popup__background-color: $white !default;
$modal-popup__box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35) !default;
$modal-popup__indent-vertical: 80px !default;
$modal-popup__padding: 30px !default;
$modal-popup__padding-top: $spacer--extra-large !default;
$modal-popup__padding-bottom: $spacer--medium !default;
$modal-popup__width: 75% !default;

$modal-slide__indent-left: 0 !default;
$modal-slide__indent-left\@medium: 72px !default;
$modal-slide__padding: 41px !default;
$modal-slide-header__padding-vertical: 33px !default;

@mixin lib-modal() {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;

    &._show {
        visibility: visible;
        .modal-inner-wrap {
            transform: translate(0, 0);
        }
    }

    .modal-inner-wrap {
        background-color: $modal-popup__background-color;
        box-shadow: $modal-popup__box-shadow;
        opacity: 1;
        pointer-events: auto;

        .modal__close-button-icon {
            fill: $black;
        }
    }
}

@mixin lib-modal-slide() {
    left: $modal-slide__indent-left;

    @include mq($screen-m) {
        left: $modal-slide__indent-left\@medium;
    }

    &._show {
        .modal-inner-wrap {
            transform: translateX(0);
        }
    }

    .modal-inner-wrap {
        height: 100%;
        overflow-y: auto;
        position: static;
        transform: translateX(100%);
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        width: auto;
    }
}

@mixin lib-modal-popup() {
    left: 0;
    overflow-y: auto;

    &._show {
        .modal-inner-wrap {
            transform: translateY(0);
        }
    }

    .modal-inner-wrap {
        margin: $modal-popup__indent-vertical auto;
        width: $modal-popup__width;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        transform: translateY(-200%);
        transition-duration: 0.2s;
        transition-timing-function: ease;
    }
}

body {
    &._has-modal {
        height: 100%;
        overflow: hidden;
        width: 100%;
    }
}

//  Modals overlay
._block-content-loading {
    position: relative;
}

.loader-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.modal-popup,
.modal-slide {
    @include lib-modal();
}

.modal-slide {
    @include lib-modal-slide();

    &._inner-scroll {
        .modal-inner-wrap {
            overflow-y: visible;
            display: flex;
            flex-direction: column;
        }

        .modal-footer,
        .modal-header {
            flex-grow: 0;
            flex-shrink: 0;
        }

        .modal-content {
            overflow-y: auto;
        }

        .modal-footer {
            margin-top: auto;
        }
    }

    .modal-content,
    .modal-footer,
    .modal-header {
        padding: 0 $modal-slide__padding $modal-slide__padding;
    }

    .modal-header {
        padding-bottom: $modal-slide-header__padding-vertical;
        padding-top: $modal-slide-header__padding-vertical;
    }
}

.modal-popup {
    &.modal-slide {
        @include lib-modal-slide();

        .modal-inner-wrap {
            margin: 0;
            max-height: none;
        }

        @include mq($screen-m) {
            @include lib-modal-popup();
        }
    }

    @include lib-modal-popup();
    //  If applied, switching outer popup scroll to inner
    &._inner-scroll {
        overflow-y: visible;

        .ie10 &,
        .ie9 & {
            overflow-y: auto;
        }

        .modal-inner-wrap {
            max-height: 90%;
            .ie10 &,
            .ie9 & {
                max-height: none;
            }
        }

        .modal-content {
            overflow-y: auto;
        }
    }

    .modal-content,
    .modal-footer,
    .modal-header {
        padding-left: $modal-popup__padding / 2;
        padding-right: $modal-popup__padding / 2;

        @include mq($screen-m) {
            padding-left: $modal-popup__padding;
            padding-right: $modal-popup__padding;
        }
    }

    .modal-footer,
    .modal-header {
        flex-grow: 0;
        flex-shrink: 0;
    }

    .modal-header {
        padding-top: $modal-popup__padding-top;
        padding-bottom: $modal-popup__padding-bottom;
    }

    .modal-footer {
        margin-top: auto;
        padding-bottom: $modal-popup__padding;
        padding-top: $modal-popup__padding;
    }

    .modal-footer-actions {
        text-align: right;
    }
}
