.actions-toolbar {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 0;

    @include mq($screen-m) {
        flex-direction: row;
    }

    .primary:not(button) {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;

        @include mq($screen-m) {
            justify-content: space-between;
            flex-direction: row;
        }

        button {
            width: 100%;
            justify-content: center;

            @include mq($screen-m) {
                max-width: 48%;
            }
        }
    }
}
