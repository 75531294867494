.newsletter {
    background: $c-black;
    padding: 20px 20px 40px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    label,
    .checkbox__label {
        color: #fff;
        display: inline;
        > a {
            color: #fff !important;
        }
    }

    .checkbox__icon {
        top: 25%;
    }
    .checkbox__field {
        top: 25%;
        background: #fff;
        opacity: 1;
        &:checked {
            ~ .checkbox__label {
                color: #fff;
            }
        }
    }

    @include mq($screen-m) {
        padding: 35px 50px 50px;
        form {
            position: relative;
            padding-left: 50%;
            .newsletter__heading {
                position: absolute;
                left: 0;
                top: 50%;
                transform:translate(0,-50%);
            }
            .newsletter__controls, .newsletter__agreements, .newsletter__button  {
                margin-left: 50px;
            }
        }
    }

    &__icon {
        display: none;

        @include mq($screen-m) {
            display: block;
            width: 33px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0.3;
        }
    }

    &__heading {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        position: relative;
        padding: 15px 0;

        @include mq($screen-m) {
            padding-left: 48px;
        }

        h1 {
            color: #ffffff;
            margin: 0;
        }

        p {
            color: #ffffff;
            opacity: 0.6;
            margin-bottom: 0;
        }
    }

    &__field {
        border: 0 !important;

        &::placeholder {
            font-style: italic;
        }
    }

    &__button {
        border: 1px solid #fff;
        position: static;
        background: none;
        color: #ffffff;
        font-family: $headings-font-family;
        min-width: 225px;
        text-align: center;
        font-size: $font-size-base;

        &:hover {
            background: #ffffff;
            color: $color-secondary;
        }
    }

    &__agreements {
        margin-left: 0;
    }

    .checkbox__label {
        & > a {
            color: $newsletter__checkbox-label-color;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
