.authentication {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include mq($screen-m) {
        justify-content: flex-end;
    }
}

.authentication {
    display: none;
}
