.product-view {
    @media screen and (min-width: 767px) {
        &__wrapper {
            grid-template-columns: 40% 60%;
        }
    }
    @include mq($screen-l) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-width: 1328px;
        &__wrapper {
            max-width: 1328px;
        }
    }
    &__title {
        margin-bottom: 4px;

        .heading--page {
            letter-spacing: 0;
        }
    }

    &__sku {
        font-size: 12px;
    }

    &__description {
        margin-bottom: 16px;
        padding: 20px;
        background: $c-black;
        .product-view__sku {
            color: #fff;
        }
        .product-view__title {
            color: #fff;
            letter-spacing: 0;
            margin-bottom: 0;
        }
        @include mq($screen-l) {
            margin-bottom: 8px;
        }
    }
    &__details {
        padding: 20px;
        border:1px solid $c-black;
        @include mq($screen-l) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &__tabs {
        #product-tabs {
            .tab__title .tab__icon {
                display: none;
            }
            .tab__content {
                border: 1px solid #000 !important;
            }
        }
    }
    .personalisation-text {
        background: $c-grey-light;
        padding: 20px;
        margin-bottom: 20px;
        font-size: 11px;

        *:last-child {
            margin-bottom: 0;
        }
    }

    .product-options-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;

        h3 {
            margin: 0;
        }

        > *:not(.input):not(.size-guide) {
            grid-column: span 4;
        }

        > *:first-child:not(.size-guide) {
            grid-column: span 3 !important;
        }

        * + .size-guide {
            text-align: right;
        }

        .size-guide {
            align-self: end;

            a {
                padding: 13px 10px;
                display: inline-block;
                background: $c-grey-light;
                white-space: normal;
                text-align: center;
                text-decoration: none;

                svg {
                    display: inline-block;
                    width: 30px;
                    vertical-align: middle;
                    margin-right: 10px;
                }

                span {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }

        .input {
            margin-bottom: 0;
            grid-column: span 2;

            p {
                margin: 0;
            }
        }

        .field {
            .control {
                background: #fff;
                padding: 0;
                position: relative;
                width: 100%;
                z-index: 1;
                cursor: pointer;
                border: 1px solid rgba(0, 0, 0, 0.2);

                &:before {
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8'%3E%3Cpath d='M12 2L10.59.59 6 5.17 1.41.59 0 2l6 6z' fill='%2373739c' /%3E%3C/svg%3E")
                        center / 12px no-repeat;
                    display: block;
                    position: absolute;
                    top: 50%;
                    -webkit-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                    -o-transform: translate(0, -50%);
                    transform: translate(0, -50%);
                    right: 0;
                    width: $select__spacing;
                    height: $select__spacing;
                    z-index: -1;
                    content: "";
                }

                select {
                    background-color: transparent;
                    padding-right: $select__spacing;
                    padding-left: $spacer--medium;
                    height: $select__spacing;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    -ms-appearance: none;
                    appearance: none;
                    cursor: pointer;
                    line-height: 100%;
                    width: 100%;
                    border: 0;
                }
            }
        }
    }

    .label {
        font-family: $headings-font-family;
    }

    #product-addtocart-button {
        &:not(:hover) {
            background: $green;
        }
    }
    .product-view__gallery {
        .gallery-placeholder {
            padding-bottom: 0 !important;
        }
    }
}

.quantity-update {
    &__button {
        &--plus {
            border-radius: 0;
        }

        &--minus {
            border-radius: 0;
        }
    }
}

.fotorama {
    &__thumb-border {
        border-color: $color-primary !important;
    }
}

.tab__content {
    table {
        margin-bottom: $spacer--large;
    }
}

.tab__title {
    text-transform: capitalize;
    color: $c-black;
    background: #fff;
    &--active {
        background: $c-black;
        color: #fff;
        font-weight: inherit;
    }
    &:hover {
        color: inherit;
        background: inherit;
        font-weight: inherit;
    }

    font-size: 16px;
}

.personalisation-title {
    h3 {
        color: #fff;
        background: #222;
        padding: 1em 2em;
        display: inline-block;
        margin-bottom: 1em;
        cursor: pointer;
    }
}

.personalisation-wrapper {
    height: 0px;
    overflow: hidden;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);

    .personalisation-text {
        grid-column: span 4;
    }
}
