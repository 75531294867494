.sparsh_banner {
    margin-bottom: 0;
    width: 100%;
    overflow: hidden;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 0;
        padding-top: 20%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        display: none;
    }

    .slick-slider {
        padding: 0;
    }

    .slick-slide {
        margin: 0;
    }

    .slick-prev,
    .slick-next {
        background: $color-primary;
        transition: all 0.3s ease;
        color: #ffffff;
        z-index: 2;
        padding: 10px;
        width: auto;
        height: auto;
        top: 33%;

        &:before {
            color: #ffffff;
            font-size: 30px;
            opacity: 1;
        }

        &:hover {
            background: #ffffff;

            &:before {
                color: $color-primary;
            }
        }
    }

    .slick-prev {
        left: -50px;
    }

    .slick-next {
        right: -50px;
    }

    &:hover {
        .slick-prev {
            left: 15px;
        }

        .slick-next {
            right: 15px;
        }
    }

    .caption {
        position: absolute;
        bottom: 40%;
        left: 0;
        right: 0;
        width: 100%;
        text-align: left;
        padding: 0;
        z-index: 2;
        bottom: 0 !important;


        @include mq($screen-m) {
            padding: 0 8px;

        }

        .container {
            max-width: 1328px;
            margin: auto;
            position: relative;
        }

        h2 {
            display: none;
        }

        .title {
            position: relative;

            &:before {
                content: " ";
                background: #0000009c;
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                filter: blur(40px);
            }
        }

        p {
            font-family: $headings-font-family;
            margin: 0;
            text-align: left;
            width: 100%;
            position: relative;

            @include mq($screen-m) {
                max-width: 50%;
            }

            @include mq($screen-l) {
                max-width: 800px;
            }

            strong {
                color: #ffffff;
                display: inline;
                padding: 3px 10px;
                box-decoration-break: clone;
                font-size: 18px;
                line-height: 18px;
                margin-left: 18px;
                position: relative;
                font-weight: 400;

                &:before {
                    background-image: url(../images/Toffs_Logo_Asterix.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    box-sizing: content-box;
                    display: block;
                    position: absolute;
                    content: " ";
                    z-index: 2;

                    left: -18px;
                    top: 0px;
                    height: 10px;
                    width: 10px;
                    margin: 8px;
                }

                @include mq($screen-m) {
                    font-size: 40px;
                    line-height: 40px;
                    margin-left: 40px;

                    &:before {
                        height: 43px;
                        width: 43px;
                        margin: 15px;
                        left: -60px;
                    }
                }

                @include mq($screen-l) {
                    font-size: 60px;
                    line-height: 60px;
                    margin-left: 60px;

                    &:before {
                        height: 23px;
                        width: 23px;
                        margin: 15px;
                        left: -40px;
                    }
                }

            }
        }
    }
}
.banner.banner--category {
    .banner__wrapper {
        .banner__button-collapse {
            position: static !important;
        }
    }
}