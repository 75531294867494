.footer {
    padding: 50px 0 10px;
    background: $c-grey-light;

    @include mq($screen-m) {
        padding: 80px 0;
    }

    .container {        
        color: $color-primary;

        @include mq($screen-s) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        @include mq($screen-l) {
            flex-wrap: nowrap;
        }

        .footer-col {
            margin-bottom: 30px;

            @include mq($screen-s) {
                width: 50%;
                padding: 0 30px 0 0;
                margin-bottom: 0;
            }

            @include mq($screen-l) {
                width: auto;
            }

            &:last-of-type {
                padding: 0;
            }
        }

        h3 {
            margin-bottom: 10px;

            @include mq($screen-m) {
                margin-bottom: 30px;
            }
        }

        a, p {
            color: $color-primary;
        }

        a {
            &:hover {
                text-decoration: underline;
            }
        }

        img {
            max-width: 170px;
        }

        ul:not(.footer__social-list) {
            list-style: none;
            column-count: 2;
            padding: 0;
            column-gap: 30px;
            line-height: $font-line-height;
            margin: 0;
        }
        
        .icon {
            width: 24px;
            height: 24px;
        }
        
        .list--with-icon {
            justify-content: flex-start;
        }
    }
}

.bank-cards-wrapper {
    background: #ffffff;

    .bank-cards-wrapper-inner {
        padding: $spacer--large $spacer--medium;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 1000px;
        margin: auto;

        > * {
            margin: 0 10px;
        }
    }
}

.copyright-content {
    text-align: center;
    margin-bottom: 20px;

    p {
        margin: 0;
    }
}