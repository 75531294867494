.breadcrumbs {
    padding-top: 10px;
    margin-bottom: 35px;

    &__link {
        background: #ffffff;
        padding: $breadcrumbs__padding!important;

        &:after {
            content:"";
            height: 0;
            width: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 6px solid #fff;
            position: absolute;
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__item {
        position: relative;
        margin-right: 10px;

        &:before, &:after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
            right: 100%;
            margin: 0;
            display: block;
        }

        &:before {            
            border-width: 0 6px 10px 0;
            border-color: transparent #fff transparent;
            top: 0;
        }

        &:after {
            border-width: 0 0 10px 6px;
            border-color: transparent transparent #fff;
            bottom: 0;
        }

        &:first-of-type, &:last-of-type {
            &:before, &:after {
                display: none;
            }
        }
    }
}

.catalog-product-view {
    .breadcrumbs {
        &__link {
            background: $c-grey-light;

            &:after {
                border-left-color: $c-grey-light;
            }
        }

        &__item {
            &:before {            
                border-color: transparent $c-grey-light transparent;
            }
    
            &:after {
                border-color: transparent transparent $c-grey-light;
            }
        }
    }
}