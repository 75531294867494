.page-wrapper {
    .mega-menu {
        border: 0;

        &__inner-list {
            display: block !important;
            background: $c-grey-light;
            border: 0;

            &--level1 {
                display: block;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                width: auto;
                padding: $spacer--large;
                transition: all 0.3s ease;
            }
        }

        #football-navigation-links {
            margin-left: -15px;
            margin-right: -15px;

            .mega-menu__inner-list {
                width: 50%;
                padding: 0 15px;
                display: inline-block !important;
                vertical-align: top;
            }
        }

        &__inner-item {
            padding: 0;
        }

        &__item {
            position: relative;
            text-transform: capitalize !important;
            span {text-transform: capitalize !important;}

            &.football_nav {
                > ul {
                    width: 1100px;

                    .mega-menu__inner-item--level1 {
                        width: 24%;
                        padding: 0 30px 0 0;
                        display: inline-block;
                        vertical-align: top;

                        &:last-of-type {
                            padding-right: 0;
                        }
                    }
                }
            }

            &--parent {
                .mega-menu__link {
                    text-transform: capitalize;
                    > svg {
                        display: inline-block;
                    }
                }
            }

            > a {
                > svg {
                    fill: $c-grey-light;
                }
            }

            &:hover {
                > a {
                    > svg {
                        fill: #fff;
                    }
                }

                > ul {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: all;
                }
            }
        }

        &__link {
            font-weight: normal;
            margin: 0;

            > svg {
                width: 5px;
                vertical-align: middle;
                margin-left: 10px;
                display: none;
                transform: rotate(90deg);
                transition: all 0.3s ease;
            }
        }

        &__inner-link {
            display: block;
            padding: 2px 25px 2px 0;
            position: relative;
            overflow: hidden;

            svg {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                width: 5px;
                transition: $transition-base;
                opacity: 0;
            }

            &:hover {
                color: $color-secondary !important;

                svg {
                    fill: $color-secondary;
                    right: 0;
                    opacity: 1;
                }
            }

            &:focus {
                background: none;
            }
        }

        div.mega-menu__inner-link {
            display: none;
        }

        .nav-filtered-title {
            padding: 0 15px 20px;
        }

        .no-items {
            padding: 0 15px;
        }

        .title {
            text-transform: uppercase;
            letter-spacing: 0.2em;
            margin-bottom: $spacer--medium;
            color: $c-black;
            font-family: $headings-font-family;
        }

        .filtered-navigation {
            .mega-menu__inner-list--level1 {
                opacity: 1;
                visibility: visible;
                pointer-events: all;
            }
        }

        .filter-input-box {
            width: 100%;
            height: $search-form__input-height;
            border: solid 2px $c-grey;
            font-size: $search-form__font-size;
            background: #fff;
            margin-bottom: $spacer--large;
            padding: $input__field-padding;

            &::placeholder {
                color: $search-form__placeholder-color;
                font-size: $search-form__font-size;
            }
        }
    }
}
