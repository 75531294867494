.form-giftcard-account {
    .actions-toolbar {
        .primary,
        .secondary {
            width: 100%;

            @include mq($screen-m) {
                max-width: 48%;
            }
        }

        .primary {
            .action {
                margin-bottom: $spacer--medium;

                @include mq($screen-m) {
                    margin-bottom: 0;
                    max-width: 100%;
                }
            }
        }

        .action {
            width: 100%;
        }
    }
}
