$sidebar-checkout__modal-content-padding         : 0 !default;
$sidebar-checkout__modal-content-padding\@small  : $spacer--medium !default;
$sidebar-checkout__modal-content-padding\@large  : $spacer--extra-large !default;
$sidebar-checkout__modal-order-summary-background: $white !default;

.sidebar-checkout {
    display: block;
    position: static;

    .modal-content {
        padding: 0;
    }
}

body._has-modal-custom {
    .sidebar-checkout .modal-content {
        padding: $sidebar-checkout__modal-content-padding;

        @include mq($screen-s) {
            padding: $sidebar-checkout__modal-content-padding\@small;
        }

        @include mq($screen-l) {
            padding: $sidebar-checkout__modal-content-padding\@large;
        }
    }

    .order-summary {
        background: $sidebar-checkout__modal-order-summary-background;
    }
}
