.pager {
    &__item {    
        &--current {
            background: $color-primary;
            color: #ffffff;   
        }

        &:after {
            display: none;
        }
    }

    &__link {
        color: $color-primary;

        &:not(.action) {
            border: 1px solid $c-grey-light;

            &:hover {
                background: $color-secondary;
                border-color: $color-secondary;
                color: #ffffff;
            }
        }
    }
}