.checkout-agreements-block {
    margin-bottom: $indent__base;

    .action-show {
        @extend .abs-action-button-as-link;
        vertical-align: baseline;
    }
}

.checkout-agreements-items {
    padding-bottom: $indent__l;

    .checkout-agreements-item {
        margin-bottom: $indent__base;
    }

    .checkout-agreements-item-title {
        border-bottom: 0;
    }
}

.checkout-agreement-item-content {
    overflow: auto;
}
