// Payment methods
$payment-methods__margin           : 0 #{-$spacer} $spacer--extra-large !default;
$payment-methods__margin\@medium   : 0 0 $spacer--extra-large !default;
$payment-methods__group-margin     : 0 !default;

// Payment method
$payemnt-method-content__padding   : 0 0 $spacer--semi-medium $spacer--semi-medium !default;
$payment-method__border            : $border-base !default;
$payment-method__border-width      : 0 0 1px 0 !default;

// Payment icon
$payment-icon__top                 : -6px !default;
$payment-icon__right               : 0 !default;
$payment-icon__width               : auto !default;
$payment-icon__height              : 35px !default;

// Radio inputs
$payment-method-title__radio-margin: 0 !default;
$payment-method-title__radio-label : $spacer--small 0 !default;
$payment-method-title__padding     : 1px 0 !default;

.payment-methods {
    margin: $payment-methods__margin;

    @include mq($screen-m) {
        margin: $payment-methods__margin\@medium;
    }

    &__heading {
        @include visually-hidden();
    }

    &__group {
        position: relative;
        margin: $payment-methods__group-margin;
    }

    // Preventing unuecessary template overwrite
    // Payment method - Extending radio inputs
    .payment-method-title {
        @extend .radio__handler;

        .radio {
            @extend .radio__field;
            margin: $payment-method-title__radio-margin;
        }

        .label {
            @extend .radio__label;
            margin: $payment-method-title__radio-label;
        }
    }

    // Payment method - Payment logo positioning
    .payment-icon {
        position: absolute;
        top: $payment-icon__top;
        right: $payment-icon__right;
        width: $payment-icon__width;
        height: $payment-icon__height;
    }

    // Payment method - Payment Item block
    .payment-method {
        border: $payment-method__border;
        border-width: $payment-method__border-width;
        padding: 0 $spacer--medium;

        &._active {
            .payment-method-content {
                display: block;
            }
        }

        &-content {
            display: none;
            padding: $payemnt-method-content__padding;
        }

        &-title {
            padding: $payment-method-title__padding;
        }
    }
}
