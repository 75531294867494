.dashboard-nav {
    &__content {
        background: $c-grey-light;
    }

    &__title {
        border: 0;
        color: #ffffff;
        background: $color-primary;
        display: inline-block;
        padding: 10px 20px;
    }

    &__item > a,
    &__link {
        padding: 5px 15px;
        text-decoration: none;
    }
}

.dashboard-form,
.dashboard-items {
    &__title {
        border-bottom: 1px solid rgba($color-primary, 0.2);
    }
}

.dashboard-table {
    &__header {
        border-bottom: 1px solid rgba($color-primary, 0.2);
    }
}
