.header {
    padding-right: 0;
    z-index: 10;
    border: 0;
    background: $c-black !important;
    position: sticky;
    top: 0;

    @include mq($screen-m) {
        padding-right: 30px;
    }

    @include mq($screen-l) {
        padding-right: 50px;
    }

    &__wrapper {
        flex-flow: row nowrap;
        border: 0;
        background-color: $c-black;
    }

    &__logo {
        order: 1;
        background: $color-primary;
        flex-shrink: 0;
        flex-grow: 0;
        border: 0;
        margin-right: auto;

        @include mq($screen-xl) {
            margin-right: 0;
        }
    }

    &__search-wrapper {
        position: absolute;
        top: calc(100% + #{$spacer});
        left: $spacer;
        right: $spacer;
        padding: 0;
        border: 0;
        opacity: 0;
        visibility: hidden;

        &.active {
            opacity: 1;
            visibility: visible;
        }

        @include mq($screen-m) {
            position: static;
            margin-left: auto;
            flex: 0 1 356px;
            width: auto;
            opacity: 1;
            visibility: visible;
        }
    }

    &__buttons {
        flex: 0 1 $header__buttons-width;
        padding: 20px;
        box-sizing: content-box;
        .header__minicart {
            border: none;
        }
    }

    &__show-search {
        @include mq($screen-m) {
            display: none;
        }
    }

    .nav-sections {
        display: none;

        @include mq($screen-xl) {
            display: block;
            order: 2;
            background: #fff;
            margin-right: auto;

            .nav-sections-items,
            .nav-sections-item-content {
                height: 100%;
            }

            .nav-sections-item-title {
                display: none;
            }

            #mega-menu {
                height: 100%;
                background-color: $c-black;

                > ul {
                    height: 100%;
                    padding: 0 20px;
                    margin: 0;
                    list-style: none;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-justify-content: flex-start;
                    -ms-flex-pack: start;
                    justify-content: flex-start;
                    -webkit-align-content: center;
                    -ms-flex-line-pack: center;
                    align-content: center;
                    -webkit-align-items: stretch;
                    -ms-flex-align: stretch;
                    align-items: stretch;
                    background-color: $c-black;

                    > li {
                        a {
                            color: $c-black;
                            text-decoration: none;
                        }

                        > a {
                            color: $c-grey-light;
                            padding: 10px 15px;
                            font-family: $headings-font-family;
                            display: -ms-flexbox;
                            display: -webkit-flex;
                            display: flex;
                            -webkit-align-self: stretch;
                            -ms-flex-item-align: stretch;
                            align-self: stretch;
                            height: 100%;

                            > span {
                                -webkit-align-self: center;
                                -ms-flex-item-align: center;
                                align-self: center;
                                text-align: center;

                                &.ui-menu-icon {
                                    display: none;
                                }
                            }
                        }

                        &:hover {
                            > a {
                                color: #ffffff;
                                background: $color-secondary;
                            }
                        }
                    }

                    .submenu {
                        position: absolute !important;
                    }
                }
            }
        }
    }
}

.header-button {
    background-color: $color-primary;
    color: #ffffff;
    flex-direction: row;
    transition: $transition-base;
    font-family: $headings-font-family;

    &:hover {
        &::before {
            transform: scaleX(0);
        }
    }
    &__text {
        display: none;
    }

    &.header__show-search {
        background: $white;
    }

    @include mq($screen-m) {
        &:hover {
            background-color: $color-secondary;
        }
    }
}

button.header-button {
    padding: 0 25px;

    .header-button__icon {
        margin-right: 5px;
    }
}

.catalog-product-view {
    header {
        margin-bottom: 30px;
        background: $c-grey-light;

        .nav-sections,
        .mega-menu,
        .mega-menu__list {
            background: none;
        }

        .search-form__input {
            background: $white;
        }
    }
}

#global-message-wrapper {
    background: $c-black;
    padding: 0 3rem;
    margin-bottom: 1rem;
    position: relative;
    display: none;

    .close {
        position: absolute;
        right: 0.5rem;
        padding: 0;
        margin: 0;
        top: calc(50% - 9px);
        height: 17px;
        width: 17px;
        svg {
            fill: $c-grey-light;
        }
    }

    .global-message {
        font-weight: 400;
        font-size: 0.9rem;
        text-align: center;
        padding: 0.5rem 0;
        color: #fff;

        p {
            font-weight: 400;
            margin-bottom: 0;
            font-size: 0.9rem;
            color: #fff;
        }

        a {
            color: #fff;
            text-decoration: underline;

            &:hover {
                color: #efefef;
            }
        }
    }
}
