#maincontent {
    .amblog-main-content {
        padding-top: 0;
    }

    .amblog-element-block {
        border: 0;
        box-shadow: none;
        background: $white;

        .amblog-title {
            text-transform: none;
            color: $headings-color;
            font-size: $font-size-medium;
            padding-bottom: 0;
        }
    }

    .amblog-read,
    .amblog-btn {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: $button__padding;
        border: $button__border;
        border-radius: $button__border-radius;
        background: $button__background;
        font-family: $button__font-family;
        font-size: $button__font-size;
        color: $button__font-color;
        transition: $button__transition;
        font-weight: $button__font-weight;

        &:hover {
            background: $button__background-hover;
            color: $button__font-color-hover;
        }

        .amblog-arrow {
            display: none;
        }
    }

    .amblog-form-search,
    .amblog-form-comment {
        .amblog-input,
        .amblog-input {
            height: $search-form__input-height;
        }

        .amblog-field::before {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .amblog-post-container {
        border: 0;

        .amblog-features {
            border-bottom: 1px solid $border-color-base;
            padding-bottom: $spacer;
        }

        .amblog-content {
            margin-top: $spacer--large;
            padding-left: 0;
            padding-right: 0;
            border-color: $border-color-base;
        }
    }

    .amblog-container-list {
        .amblog-features {
            border: 0;
        }
    }

    .amblog-post-container .amblog-features.-post,
    .amblog-grid-list > .amblog-item .amblog-features.-post,
    .amblog-grid-list .slick-slide .amblog-item .amblog-features.-post {
        padding-left: 0;
        padding-right: 0;
    }

    .amblog-ref {
        color: $link__color;
    }

    .amblog-categories {
        padding: $spacer 0 $spacer--medium;

        .amblog-category {
            padding: 0;
            border: 0;

            .amblog-link {
                padding: 8px 20px;
            }
        }
    }
}
