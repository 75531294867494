@import 'store-pickup-modal-variables';

.store-pickup-modal {
    &__search {
        display: flex;
        flex-direction: column;

        @include mq($screen-m) {
            flex-direction: row;
            align-items: flex-end;
        }
    }

    &__search-field {
        flex: $store-pickup-modal__search-field-flex;
    }

    &__search-button {
        margin-bottom: $store-pickup-modal__search-button-margin-bottom;
        height: $store-pickup-modal__search-button-height;
    }

    &__paragraph {
        margin-bottom: $spacer--large;
    }

    &__table {
        th,
        td {
            &:first-child {
                @include mq($screen-m) {
                    border-right: $store-pickup-modal__table-cell-first-border-right;
                }
            }

            &:last-child {
                @include mq($screen-m) {
                    text-align: $store-pickup-modal__table-cell-last-text-align;
                }
            }
        }
    }
}
