$checkout-payment-method-title__border           : $checkout-shipping-method__border !default;
$checkout-payment-method-title__padding          : $checkout-shipping-method__padding !default;
$checkout-payment-method-title-mobile__padding   : 15px !default;

$checkout-payment-method-content__padding__xl    : 22px !default;

$checkout-billing-address-form__max-width        : 100% !default;
$checkout-billing-address-form__max-width\@medium: 70% !default;
$checkout-billing-address-form__max-width\@large : 496px !default;

$checkout-billing-address__text-font-size        : $font-size-base !default;
$checkout-billing-address__text-margin           : 0 0 $spacer !default;

.checkout-payment-method {
    .payment-method-content {
        .fieldset {
            &:not(:last-child) {
                margin: 0 0 $indent__base;
            }
        }
    }

    .field-select-billing,
    .billing-address-form {
        max-width: $checkout-billing-address-form__max-width;
        margin: 0 auto;
        @include mq($screen-m) {
            max-width: $checkout-billing-address-form__max-width\@medium;
        }
        @include mq($screen-l) {
            margin: 0;
            max-width: $checkout-billing-address-form__max-width\@large;
        }
    }

    .payment-method-billing-address {
        margin: 0 0 $indent__base;

        .billing-address-details {
            line-height: 1.5;

            .action-edit-address {
                margin-top: 10px;
            }

            &__text {
                font-size: $checkout-billing-address__text-font-size;
                margin: $checkout-billing-address__text-margin;
            }
        }
    }

    .payment-method-note {
        & + .payment-method-billing-address {
            margin-top: $indent__base;
        }
    }

    .field-select-billing {
        & > .label {
            @extend .abs-visually-hidden;
        }
    }

    .payment-method-iframe {
        background-color: transparent;
        display: none;
        width: 100%;
    }

    .no-payments-block {
        margin: $indent__base 0;
    }

    .payments {
        .legend {
            @extend .abs-visually-hidden;
        }
    }
    .actions-toolbar {
        .amgiftcard-check {
            .action {
                width: 100%;
                margin-bottom: $spacer--medium;
                @include mq($screen-m) {
                    margin-bottom: 0;
                }
            }
            @include mq($screen-m) {
                & + .primary {
                    max-width: 48%;
                    .button {
                        max-width: 100%;
                    }
                }
            }

        }
    }
}

@include mq($max-screen: $screen__m) {
    .checkout-payment-method {

        .payment-method-title {
            padding: $checkout-payment-method-title-mobile__padding;
        }

        .payment-method-content {
            padding: 0 $checkout-payment-method-title-mobile__padding $indent__base;
        }

        .payment-method-billing-address {
            .action-cancel {
                margin-top: $indent__s;
            }
        }
    }
}

@include mq($screen__m) {
    .checkout-payment-method {
        .fieldset {
            & > .field-select-billing {
                & > .control {
                    float: none;
                    width: 100%;
                }
            }
        }

        .payment-method-content {
            .fieldset {
                & > .field {
                    margin: 0 0 $indent__base;

                    &.type {
                        .control {
                            margin-left: 25.8%;
                        }

                        &.no-detection {
                            .control {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
