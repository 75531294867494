/* Colours */
$c-black: #222222;
$c-grey: #777777;
$c-grey-light: #ededed;
$c-blue: #2d3e5a;
$green: #26b161;
$blue: #265ab1;

$color-primary: $c-black;
$color-secondary: $c-blue;
$c-text-primary: $c-grey;

$link__color: $color-primary;
$link__text-decoration: underline;
$link__color--invert: $color-primary;
$link__color-hover--invert: $color-secondary;

/*  Fonts */
$font-family-base: "Favorit";
$headings-font-family: "Favorit";
$font-family-secondary: "Favorit";
$font-size-base: 15px;

$headings-color: $color-primary;
$paragraph-color: $c-text-primary;

/* Header */
$header__logo-padding: 0 20px;
$header__logo-padding\@medium: 20px 50px;
$headings-font-weight: 400;
$headings-text-transform: uppercase;
$headings-text-transform--page: uppercase;
$heading-font-size--first-level\@screen-m: 18px;
$heading-font-size--second-level\@screen-m: 18px;
$heading-font-size--third-level: 16px;
$headings-line-height: 1.1;
$headings-margin: 0 0 $spacer--medium;

$header__wrapper-padding\@medium: 0;

$header__buttons-width: auto;
$header__buttons-width\@medium: auto;
$header__buttons-width\@extra-large: auto;

$header-button__icon-margin: 0;
$header-button__icon-margin--medium: 0;
$header-button__margin: 0 0 0 1px;
$header-button__icon-padding: 0;
$header-button__size: 50px;
$icon__size: 17px;

/* Button */
$button__border-radius: 0;
$button__font-color-hover: $white;
$button__font-color-hover--secondary: $white;
$button__font-color: $white;
$button__background-hover: $color-secondary;
$button__background-hover--icon: $color-secondary;
$button__fill-hover--icon: #fff;
$button__background--secondary: none;

/* Logo */
$logo__max-width: 120px;
$logo__max-width\@medium: 250px;
$logo__image-height: auto;
$logo__image-height\@medium: auto;

/* Search */
$search-form__height: 50px;
$search-form__input-height: 50px;
$search-form__border: none;
$search-form__font-size: $font-size-base;
$search-form__placeholder-color: $color-primary;
$search-form__width--extra-large: 100%;

/* Product Grid */
$catalog-grid-item__border: 1px solid $c-grey-light;
$catalog-grid-item__content-height: 66px;
$catalog-grid-item__content-height\@large: 66px;
$catalog-grid-item__content-padding: 10px 30px;
$catalog-grid-item__name-margin-botttom: 0;
$catalog-grid__gap: 0;
$catalog-grid__border: none;

$catalog-list__margin: 0;
$catalog-list__sidebar-margin\@medium: 0 $spacer--medium 30px 0;

/* Product View */
$product-view__sku-margin-bottom: 10px;
$product-view__sku-margin-bottom\@medium: 15px;
$product-view__sku-color: $c-grey;
$product-view__slider-margin-bottom\@medium: 30px;
$product-view__slider-heading-font-size: 16px;
$product-view__swatch-option-title-font-weight: $font-weight-normal;

/* Newsletter */
$newsletter__field-height: 50px;
$newsletter__field-border-radius: 0;
$newsletter__input-background: $white;
$newsletter__field-padding: 0 20px;
$newsletter__input-color: $c-text-primary;
$newsletter__controls-height: 50px;
$newsletter__input-color--placeholder: rgba(0, 0, 0, 0.5);

/* Social Icons */
$footer__social-icon-color: $color-primary;
$list__padding--with-icon: 0 8px;

/* Select */
$select__padding: 0 $spacer;
$select__border: none;
$select__border-radius: 0;
$select__field-background: $c-grey-light;
$select__input-border-color: none;
$select__field-list-border-radius--single: 0;
$select__spacing: 50px;

/* Toolbar */
$toolbar__border: none;
$toolbar__select-height: 40px;
$toolbar__secondary-text-color: $c-black;
$toolbar__select-dropdown-border-radius: 0;
$toolbar__select-single-border-radius: 0;
$toolbar__select-border-radius: 0;
$toolbar__select-height: 40px;
$toolbar__select-field-item-height: 40px;
$toolbar__select-field-list-height: 40px;
$toolbar__dropdown-icon-area-size: 40px;
$toolbar__select-border: 0;

/* Breadcrumbs */
$breadcrumbs__padding: 1px 7px;
$breadcrumbs__color: $color-primary;
$breadcrumbs__color--active: $color-primary;
$breadcrumbs__min-height: 0;

/* Pager */
$pager__item-size: 32px;
$pager__item-margin: 0 2px;

/* Dropdown List */
$dropdown-list__bg-color--secondary: none;
$dropdown-list__bg-color: none;
$dropdown-list__item-bg-color--open: none;

/* Message */
$message__border-radius: 0;
$message__padding: $spacer--medium $spacer--extra-large;
$message__font-size: $font-size-base;
$message__background: $blue;
$message__background--success: $green;
$message__background--error: $red;
$message__color: $white;
$message__color--success: $white;
$message__color--error: $white;
$message__border--success: 1px solid $green;
$message__icon-fill: $white;
$message__icon-fill--success: $white;
$message__icon-fill--error: $white;

/* Input */
$input__field-spacing: 50px;
$input__field-border-radius: 0;
$input__field-border-radius--textarea: 0;

/* Login */
$login__forgot-password-color: $color-primary;

/* Discount */
$discount__field-background: $c-grey-light;
$discount__field-border-radius: 0;
$discount__field-height: 50px;
$discount__field-color: $color-primary;
$discount__field-color--placeholder: rgba($color-primary, 0.7);
$discount__button-height: 50px;
$discount__button-border-radius: 0;

/* Table */
$table__border-radius: 0;
$table__border: 1px solid $c-grey-light;
$table__th-background: $color-primary;
$table__tfoot-background: $c-grey-light;

$tab__title-color: $color-primary;
$tab__title-color--active: $white;
$tab__title-background: $c-grey-light;
$tab__title-background--active: $c-grey;
$tab__title-background--large-active: $color-primary;
$tab__title-font-weight--large-active: normal;
$tab__title-font-weight: normal;
$tab__title-border: none;
$tab__title-border--large: none;
$tab__title-border--large-active: none;

// Menu
$mega-menu__inner-list-padding: 0;
$mega-menu__inner-item-padding--parent: 0;

$modal__close-button-icon-color: $white;

$container__max-width: 1328px;
$container__margin: 8px;
$container__margin\@medium: $container__margin;
$container__margin\@large: $container__margin;
