$estimated-section__padding                  : $spacer--semi-medium !default;
$estimated-section__margin                   : 0 #{-$spacer} $spacer--semi-medium !default;
$estimated-section__border-bottom            : $border-base !default;
$estimated-section__price-font-size          : $font-size-medium !default;
$estimated-section__trigger-button-border    : none !default;
$estimated-section__trigger-button-padding   : 0 !default;
$estimated-section__trigger-button-background: transparent !default;
$estimated-section__counter-top              : 10px !default;
$estimated-section__counter-left             : 27px !default;
$estimated-section__counter-width            : $spacer !default;
$estimated-section__counter-height           : $spacer !default;
$estimated-section__counter-border           : 1px solid $white !default;
$estimated-section__counter-border-radius    : 14px !default;
$estimated-section__counter-line-height      : 14px !default;
$estimated-section__counter-font-size        : $font-size-extra-small !default;
$estimated-section__counter-text-align       : center !default;
$estimated-section__counter-color            : $white !default;
$estimated-section__counter-background       : $red !default;
$estimated-section__icon-width               : 48px !default;
$estimated-section__icon-height              : 48px !default;
$estimated-section__icon-padding             : 12px !default;
$estimated-section__icon-border              : $border-base !default;
$estimated-section__icon-border-width        : 0 1px 0 0 !default;
$estimated-section__icon-fill-last-child     : $gray-dark !default;
$estimated-section__icon-padding-last-child  : 14px !default;
$estimated-section__icon-border-last-child   : 0 0 0 0 !default;

.estimated-section {
    display: flex;
    justify-content: space-between;
    padding: $estimated-section__padding;
    margin: $estimated-section__margin;
    border-bottom: $estimated-section__border-bottom;

    @include mq($screen-m) {
        display: none;
    }

    &__price {
        display: flex;
        flex-direction: column;
        font-size: $estimated-section__price-font-size;
    }

    &__trigger {
        display: flex;
    }

    &__trigger-button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: $estimated-section__trigger-button-border;
        padding: $estimated-section__trigger-button-padding;
        background: $estimated-section__trigger-button-background;
    }

    &__counter {
        position: absolute;
        top: $estimated-section__counter-top;
        left: $estimated-section__counter-left;
        width: $estimated-section__counter-width;
        height: $estimated-section__counter-height;
        border: $estimated-section__counter-border;
        border-radius: $estimated-section__counter-border-radius;
        line-height: $estimated-section__counter-line-height;
        font-size: $estimated-section__counter-font-size;
        text-align: $estimated-section__counter-text-align;
        color: $estimated-section__counter-color;
        background-color: $estimated-section__counter-background;
    }

    &__icon {
        width: $estimated-section__icon-width;
        height: $estimated-section__icon-height;
        padding: $estimated-section__icon-padding;
        border: $estimated-section__icon-border;
        border-width: $estimated-section__icon-border-width;

        &:last-child {
            fill: $estimated-section__icon-fill-last-child;
            padding: $estimated-section__icon-padding-last-child;
            border-width: $estimated-section__icon-border-last-child;
        }
    }
}
