$button__font-size                  : $font-size__base !default;
$button__font-weight                : $font-weight__semibold !default;
$button__line-height                : $line-height__base !default;

$button__background                 : $color-gray-darken0 !default;
$button__border                     : 1px solid $color-gray-darken2 !default;
$button__border-radius              : 3px !default;

$button__shadow                     : none !default;

$button__font-size__s               : 11px !default;

@mixin lib-button() {
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }
}

//
//  Link as button
//  ---------------------------------------------

@mixin lib-link-as-button() {
    display: inline-block;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

//
//  Button as link
//  ---------------------------------------------

@mixin lib-button-as-link(
    $_link-color      : $link__color,
    $_link-color-hover: $link__hover__color,
    $_line-height     : $line-height__base,
    $_disabled_opacity: 0.5,
    $_padding         : 0
) {
    display: inline;
    line-height: $_line-height;
    padding: $_padding;
    background: none;
    border: 0;
    @include lib-link();
    &:hover,
    &:focus {
        color: $_link-color-hover;
    }

    &:hover,
    &:active,
    &:focus {
        background: none;
        border: 0;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        color: $_link-color;
        opacity: $_disabled_opacity;
        cursor: default;
        pointer-events: none;
        text-decoration: underline;
    }
}

@mixin lib-button-revert-secondary-color(
    $_button-color            : $primary__color,
    $_button-background       : $color-gray95,
    $_button-border           : 1px solid $color-gray-darken2,

    $_button-color-hover      : $color-gray-darken3,
    $_button-background-hover : $color-gray-darken1,
    $_button-border-hover     : $_button-border,

    $_button-color-active     : $_button-color,
    $_button-background-active: $_button-background-hover,
    $_button-border-active    : $_button-border
) {
    &:focus,
    &:active {
        background: $_button-background-active;
        border: $_button-border-active;
        color: $_button-color-active;
    }

    &:hover {
        background: $_button-background-hover;
        border: $_button-border-hover;
        color: $_button-color-hover;
    }
}
