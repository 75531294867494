$big-match-vs-size: 50px !default;
$big-match-vs-size-medium: 124px !default;
$big-match-name-padding: 20px !default;

.big-match {
  a {
    text-decoration: none;
  }

  &_wrapper {
    position: relative;
    overflow: hidden;
    display: grid;
    &:after {
        content: "VS";
        font-family: $headings-font-family;
        font-size: 24px;
        width: $big-match-vs-size;
        height: $big-match-vs-size;
        border-radius: $big-match-vs-size;
        line-height: $big-match-vs-size;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translate(0, -50%);
        color: #ffffff;
        background: $color-primary;
        z-index: 2;
        pointer-events: none;
        border: 2px solid #fff;
    }

    /*@include mq($screen-m) {
      grid-template-columns: repeat(2, 1fr);

      &:before {
        content: "";
        width: 2px;
        height: 200%;
        transform: translate(-50%, -50%);
        background: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        pointer-events: none;
        z-index: 1;

        @include mq($screen-l) {
          width: 3px;
        }
      }

      &:after {
        left: 50%;
        transform: translate(-50%, -50%);

        @include mq($screen-l) {
          font-size: 48px;
          width: $big-match-vs-size-medium;
          height: $big-match-vs-size-medium;
          border-radius: $big-match-vs-size-medium;
          line-height: $big-match-vs-size-medium;
        }
      }
    }*/
  }

  &_inner {
    position: relative;
    align-items: center;
    padding: 20px 0;
    aspect-ratio: 1;
    min-height:100vw;

    @include mq($screen-m) {
      padding: 40px 0;
      min-height:auto;
      aspect-ratio: 1.2;
    }

    @include mq($screen-l) {
      display: flex;
      align-items: center;
      padding: 60px 0;
      aspect-ratio: 1.2;
    }

    @media (min-width: 1200px) {
      aspect-ratio: 1.5;
    }

    @media (min-width: 1328px) {
      aspect-ratio: 1.8;
    }

    &_match {
      flex: 1 1 auto;
      padding: 0 15px;
      text-align: center;
      order: 1;

      @include mq($screen-l) {
        padding: 0 10px 0 30px;
      }

      &_image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &_team-name {
      @include mq($screen-l) {
        flex: 0 0 300px;
        order: 2;
      }

      h2 {
        display: inline-block;

        @include mq($screen-l) {
          margin: 0;
        }
      }

      &_link {
        display: block;
        font-family: $headings-font-family;
        letter-spacing: 0.2em;

        span {
          background: #ffffff;
          color: $color-primary;
          display: inline;
          padding: 5px 10px;
          box-decoration-break: clone;
          line-height: 260%;
        }

        @include mq($screen-m) {
          span {
            padding: 10px 20px;
          }
        }

        @include mq($screen-l) {
          span {
            padding: 10px
              calc(
                #{$big-match-vs-size-medium} / 2 + #{$big-match-name-padding}
              )
              10px $big-match-name-padding;
          }
        }
      }
    }

    @include mq($screen-l) {
      &:nth-of-type(odd) {
        .big-match_inner_team-name {
          text-align: right;
        }
      }

      &:nth-of-type(even) {
        .big-match_inner_match {
          order: 2;
          padding: 0 30px 0 10px;
        }

        .big-match_inner_team-name {
          order: 1;

          &_link {
            span {
              padding: 10px $big-match-name-padding 10px
                calc(
                  #{$big-match-vs-size-medium} / 2 + #{$big-match-name-padding}
                );
            }
          }
        }
      }
    }

    &_button {
      background: $color-primary;
      color: #ffffff;
      font-family: $headings-font-family;
      line-height: 1.1;

      font-weight: 300;
      padding: 0.5rem 2rem;
      display: block;
      z-index: 2;
      position: absolute;
      bottom: 1em;
      transform: translate(-50%, 0%);
      left: 50%;

      @include mq($screen-l) {
        font-size: 1.3rem;
      }
    }
  }

  &_background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    p {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 100% !important;
      }
    }
  }

  h1 {
    background: $color-primary;
    color: #ffffff;
    text-align: center;
    padding: 10px 5px;
    margin: 0 0 2px;

    @include mq($screen-l) {
      padding: 20px 10px;
    }
  }
}
