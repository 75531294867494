// Base elements
.field-select-billing {
    .control {
        @extend .select;
        @extend .select--native;
    }

    select.select {
        @extend .select__field;
    }
}

ol {
    &.opc {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
}

fieldset {
    &.field {
        @extend .fieldset;
    }
}

// Layout
.checkout-container {
    @include mq($screen-l) {
        display: flex;
        flex-wrap: wrap;
        max-width: $checkout-container__width;
        margin: $checkout-container__margin;
    }
}

.progress-bar {
    @include mq($screen-l) {
        max-width: $progress-bar__width;
        flex-basis: $progress-bar__width;
    }
}

[data-role="checkout-messages"] {
    width: $checkout__messages-width;

    @include mq($screen-m) {
        max-width: $checkout__max-width\@medium;
        margin: $checkout-messages__margin;
    }

    @include mq($screen-l) {
        max-width: $checkout-messages__width;
        flex-basis: $checkout-messages__width;
    }
}

.opc-wrapper {
    @include mq($screen-m) {
        max-width: $checkout__max-width\@medium;
        margin: $opc-wrapper__margin;
    }

    @include mq($screen-l) {
        max-width: $opc-wrapper__width;
        flex-basis: $opc-wrapper__width;
        padding: $opc-wrapper__padding\@large;
    }

    @include mq($screen-xl) {
        padding: $opc-wrapper__padding\@xlarge;
    }
}

.opc-sidebar {
    z-index: $z-index-low;

    @include mq($screen-l) {
        max-width: $opc-sidebar__width\@medium;
        flex-basis: $opc-sidebar__width\@medium;
    }
}
