$checkout-payment-option-title-icon__font-size    : 32px !default;
$checkout-payment-option-title-icon__margin       : 0 !default;
$checkout-payment-method-option__month-width      : 180px !default;
$checkout-payment-method-option__year-width       : 110px !default;


.checkout-payment-method {
    .credit-card-types {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;

        .item {
            display: inline-block;
            list-style: none;
            margin: 15px $indent__xs;
            vertical-align: top;

            &._active {
                font-weight: $font-weight__bold;

                img {
                    filter: grayscale(0%);
                }
            }

            &._inactive {
                opacity: 0.4;
            }

            span {
                display: inline-block;
                padding-top: 6px;
                vertical-align: top;
            }
        }

        img {
            filter: grayscale(100%);
            transition: all 0.6s ease;
        }
    }

    .ccard {
        .legend {
            @extend .abs-visually-hidden;
        }

        .fields {
            & > .year {
                padding-left: $indent__xs;
            }
        }

        .month {
            .select {
                width: $checkout-payment-method-option__month-width;
            }
        }

        .year {
            .select {
                width: $checkout-payment-method-option__year-width;
            }
        }

        .number {
            .input-text {
                width: 225px;
            }
        }

        & > .field.cvv {
            & > .control {
                padding-right: $indent__base;
                width: auto;
            }
        }

        .cvv {
            .input-text {
                width: 55px;
            }
        }

        &.fieldset {
            & > .field {
                .fields.group.group-2 {
                    .field {
                        width: auto !important; // sass-lint:disable-line no-important
                    }
                }
            }
        }
    }
}

@include mq($max-screen: $screen__m) {
    .checkout-payment-method {
        .payment-option {
            margin: 0;
        }
    }
}


@include mq($screen__m) {
    .checkout-payment-method {
        .payment-option-content {
            .payment-option-inner {
                + .actions-toolbar {
                    margin-left: 0;
                }
            }
        }

        .credit-card-types {
            justify-content: flex-start;

            .item {
                margin: $indent__xs;
            }
        }
    }
}
