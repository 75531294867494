.contact-wrapper {
    display: flex;

    > div {
        padding: 50px;
        width: 100%;
    }

    &__section-1 {
        background: $c-grey-light;
    }

    &__section-2 {
    }
}

.opening-times {
    display: grid;
    width: 100%;
    grid-template-columns: 105px auto;
    line-height: 1.5;
    color: $c-text-primary;

    dt {
        font-weight: $font-weight-bold;
    }
}