.fieldset {
    margin: 0;
    padding: 0;
    border: 0;

    & > .field {
        .control {
            &._with-tooltip {
                @extend .abs-field-tooltip;
            }

            .note {
                display: block;
            }
        }

        &._required,
        &.required {
            & > {
                label,
                .label {
                    &:after {
                        content: '*';
                        color: #e02b27;
                        font-size: 1.2rem;
                        margin: 0 0 0 3px;
                    }
                }
            }
        }
    }
}
