.catalog-grid-item {
    border-bottom-color: transparent;
    position: relative;
    overflow: hidden;
    border: 1px solid #ededed;

    &__details {
        border-top: 0;
        padding: 10px;
        position: absolute;
        top: 100%;
        transform: translateY(-83px);
        width: 100%;
        background: #fff;
        transition: all 0.3s ease;

        @include mq($screen-m) {
            padding: $catalog-grid-item__content-padding;
        }
    }

    &:hover {
        .catalog-grid-item__details {
            transform: translateY(-100%);
        }
    }
    &__link {
        padding: 30px 20px 10px;
        text-decoration: none;
        display: block;

        @include mq($screen-m) {
            padding-left: 50px;
            padding-right: 50px;
        }
    }
    &__name {
        min-height: 48px;
    }
    &__name-link {
        font-family: $headings-font-family;
        text-decoration: none;
    }

    &__price {
        color: $color-secondary;
    }

    + .catalog-grid-item {
        border-left-color: transparent;
    }

    &:nth-of-type(3n + 1) {
        border-left: 1px solid #ededed;
    }

    &:nth-last-of-type(-n + 3) {
        border-bottom: 1px solid #ededed;
    }

    .tocart {
        background: transparent;
        border: 1px solid $c-black;
        color: $c-black;
        width: 100%;
        margin: 0 0 0 0;
        &:hover {
        }
        .catalog-grid-item__cart-icon {
            margin-left: 1em;
        }
    }
}
.product-view__slider.related, .product-view__slider.upsell {
    .catalog-grid-item.product-view__grid-item {
        min-width: 300px;
        .catalog-grid-item__details {
            transform: translateY(-100px);
            .catalog-grid-item__name {
                font-weight: 400;
                line-height: 1.6;
                text-transform: none;
                font-size: 15px;
            }
        }
    }
}
.catalog-list {
    .categories-list__link {
        padding: 6px 4px !important;
    }
}