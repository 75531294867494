.featured-cat-list {
    grid-template-columns: repeat(3, 1fr);
}

.featured-cat-list,
.cat-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2px;
    margin-bottom: 30px;

    @include mq($screen-m) {
        grid-template-columns: repeat(3, 1fr);
    }

    .featured-cat,
    .cat {
        position: relative;
        background: #000000;

        >a {
            display: block;
            height: 100%;
        }

        .category-image {
            overflow: hidden;
            height: 100%;

            img {
                transition: transform 0.6s ease, opacity 0.3s ease;
                object-fit: cover;
                object-position: center;
                width: 100% !important;
                height: 100%;
            }
            .team-colours {
                .team-colour-left {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: 50%;
                }
                .team-colour-right {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    width: 50%;
                }
            }
        }

        .category-item-name {
            transition: $transition-base;
            position: absolute;
            left: 0;
            right: 0;
            padding: 6px 10px;
            background: #000;
            padding: 5px 0 0 0;
            font-size: 18px;
            line-height: 18px;
            bottom: 0;
            text-shadow: none;
            min-height: 48px;
            a {
                color: #ffffff;
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                transition: $transition-base;
                text-decoration: none;

                padding: 5px 10px;
                box-decoration-break: clone;
                display: inline;
                line-height: 120%;
            }
        }


        &:hover {
            .category-image {
                img {
                    transform: scale(1.1);
                    opacity: 0.5;
                }
            }
        }
    }

}
.featured-cat-list {
    grid-template-columns: repeat(2, 1fr); 
    @include mq($screen-m) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.cat-list {
    margin-bottom: 40px;
    grid-template-columns: repeat(2, 1fr);

    @include mq($screen-m) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include mq($screen-l) {
        grid-template-columns: repeat(4, 1fr);
    }

    @include mq($screen-xl) {
        grid-template-columns: repeat(6, 1fr);
    }

    +.catalog-list {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        padding-top: 40px;
    }
}
