//
//  Typography variables
//  _____________________________________________

//
//  Fonts
//  ---------------------------------------------

//  Path
$font-path: "../../fonts/" !default;
$icons__font-path: "../fonts/Blank-Theme-Icons/Blank-Theme-Icons" !default;

//  Font families
$font-family__sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-family__serif     : Georgia, 'Times New Roman', Times, serif !default;
$font-family__monospace : Menlo, Monaco, Consolas, 'Courier New', monospace !default;
$font-family__base      : $font-family__sans-serif !default;

//  Sizes
$font-size__base: 14px !default;
$font-size__xl  : 21px !default;
$font-size__l   : 18px !default;
$font-size__s   : 12px !default;
$font-size__xs  : 11px !default;

//  Weights
$font-weight__light   : 300 !default;
$font-weight__regular : 400 !default;
$font-weight__heavier : 500 !default;
$font-weight__semibold: 600 !default;
$font-weight__bold    : 700 !default;

//  Styles
$font-style__base: normal !default;
$font-style__emphasis: italic !default;

//  Line heights
$line-height__base: 1.428571429 !default;
$line-height__computed: floor($font-size__base * $line-height__base) !default;
$line-height__xl: 1.7 !default;
$line-height__l: 1.5 !default;
$line-height__s: 1.33 !default;

//  Colors
$text__color: $primary__color !default;
$text__color__intense: $primary__color__darker !default;
$text__color__muted: $primary__color__lighter !default;

//
//  Borders
//  ---------------------------------------------

$border-color__base: darken($color-white, 18%) !default;
$border-width__base: 1px !default;

//
//  Focus
//  ---------------------------------------------

$focus__color: $color-sky-blue1 !default;
$focus__box-shadow: 0 0 3px 1px $focus__color !default;

//
//  Links
//  ---------------------------------------------

$link__color: $color-blue1 !default;
$link__text-decoration: none !default;

$link__visited__color: $link__color !default;
$link__visited__text-decoration: none !default;

$link__hover__color: $color-blue2 !default;
$link__hover__text-decoration: underline !default;

$link__active__color: $active__color !default;
$link__active__text-decoration: underline !default;

//
// Product links
//

$product-name-link__color: $link__color !default;
$product-name-link__text-decoration: $link__text-decoration !default;
$product-name-link__color__visited: $link__visited__color !default;
$product-name-link__text-decoration__visited: $link__visited__text-decoration !default;
$product-name-link__color__hover: $link__hover__color !default;
$product-name-link__text-decoration__hover: $link__hover__text-decoration !default;
$product-name-link__color__active: $link__active__color !default;
$product-name-link__text-decoration__active: $link__active__text-decoration !default;

//  Code blocks
$code__background-color: $gray-lighter !default;
$code__color: $primary__color__darker !default;
$code__font-size: $font-size__s !default;
$code__padding: 2px $spacer--extra-small !default;

$pre__background-color: $gray-lighter !default;
$pre__border-color: $border-color__base !default;
$pre__border-width: $border-width__base !default;
$pre__color: $primary__color__darker !default;

$kbd__background-color: $gray-lighter !default;
$kbd__color: $primary__color__darker !default;

//  Blockquote
$blockquote__border-color: $border-color__base !default;
$blockquote__border-width: 0 !default;
$blockquote__content-before: '\2014 \00A0' !default;
$blockquote__margin: 0 0 $indent__base $indent__xl !default;
$blockquote__padding: 0 !default;

$blockquote-small__color: $primary__color !default;
$blockquote-small__font-size: $font-size__xs !default;

$cite__font-style: $font-style__base !default;
