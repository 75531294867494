// Component variables
@import "../Snowdog_Components/components/Atoms/variables/variables";
@import "variables";

// Components
@import "../Snowdog_Components/components/styles/critical";
@import "../Snowdog_Components/components/styles/checkout";
@import "../Snowdog_Components/components/styles/non-critical";

// Components
@import "../Snowdog_Components/components/styles/critical-checkout";
@import "../Magento_Theme/styles/module-critical";

.page-main,
.footer,
.breadcrumbs {
    display: block;
}

// Theme styles
@import "theme";

@import "imports/typography.scss";
@import "imports/header.scss";
@import "imports/breadcrumbs.scss";
@import "imports/footer.scss";
@import "imports/search.scss";
@import "imports/usps.scss";
@import "imports/featured-categories.scss";
@import "imports/big-match.scss";
@import "imports/best-sellers.scss";
@import "imports/newsletter.scss";
@import "imports/youth-discount.scss";
@import "imports/mmenu.scss";
@import "imports/banners.scss";
@import "imports/catalog-grid.scss";
@import "imports/page-title.scss";
@import "imports/pager.scss";
@import "imports/filters.scss";
@import "imports/product-view.scss";
@import "imports/checkout.scss";
@import "imports/account.scss";
@import "imports/toolbar.scss";
@import "imports/navigation.scss";
@import "imports/cms.scss";
@import "imports/blog.scss";
@import "imports/am-giftcard.scss";
@import "imports/contact.scss";
@import "imports/modals.scss";
