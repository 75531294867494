.popup-pointer {
    margin-left: -14px;
    position: absolute;
    top: -14px;
}

.ui-dialog {
    &.popup {
        @include mq($screen__s) {
            width: 420px;
        }
    }
}
